import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { getBookingDetails,cancelBooking } from "./store/actions/commonActions";
import Link from "@material-ui/core/Link";
import EventIcon from "@material-ui/icons/Event";
import ButtonBase from "@material-ui/core/ButtonBase";
import EmailIcon from "@material-ui/icons/Email";
import DescriptionIcon from "@material-ui/icons/Description";
import CallIcon from "@material-ui/icons/Call";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SubjectIcon from "@material-ui/icons/Subject";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextField from "@material-ui/core/TextField"
import isEmail from 'validator/es/lib/isEmail';
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AppBar from "@material-ui/core/AppBar";
import Footer from "./common/footer";
import moment from "moment";
import Toolbar from "@material-ui/core/Toolbar";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: "grey",
    backgroundImage: `url(https://images.unsplash.com/photo-1432847712612-926caafaa802?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80)`,
    color: "white",
    padding: theme.spacing(8, 0, 6),
    paddingLeft: 16,
    backgroundSize: "cover",
    paddingRight: 16,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
      marginTop: theme.spacing(8),
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 200,
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  buttonBase: {
    padding: 16,
    paddingTop: 24,
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 24,
    fontSize: 16,
    fontWeight: "bold",
    borderRadius: 4,
    width: "100%",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [bookngDetails, setBookingDetails] = useState({
      _id:null,
      firstName:"",
      lastName:"",
      start:"",
      end:"",
      service:{title:""}
  });
  const [services, setServiceData] = useState([]);
  const [email,setEmail]=useState("");
  const [err,setError]=useState("")
  const [isDeleted,setDelete] = useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { ui } = useSelector((state) => state);

  const cancelMyBooking=(id , email)=>{
    if(isEmail(email))
    cancelBooking(id,email).then(data=>{
      setDelete(true)
        toast("Appointment deleted succesfully", {
            type: "success",
            autoClose: 5000,
          });
    }).catch(error => {
      console.log(error)

//       if(error.response.status==409)
//          setError("Email does't match")
// else
//         toast("Unexpected error please try again", {
//             type: "error",
//             autoClose: 5000,
//           });
  })
  else
  setError("Please enter valid email")
}

  useEffect(async () => {
    window.scrollTo(0, 0);
    getBookingDetails(props.match.params.id).then(data=>{
        setBookingDetails(data.data)
    }).catch(error => {

        if(error.response.status=="404"){
            toast("Unable to find requested resource", {
                type: "error",
                autoClose: 5000,
              });
        }
        else
        toast("Unexpected error please try again", {
            type: "error",
            autoClose: 5000,
          });
    })
  }, []);

  useEffect(async () => {}, []);

  let history = useHistory();
  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar
        variant="fixed"
        position="relative"
        style={{ background: "#fff", boxShadow: "none" }}
      >
        <Toolbar>
          {history.length && (
            <IconButton
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => props.history.goBack()}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}

          {
            <Typography
              variant="h6"
              color="inherit"
              style={{ color: "#000", fontWeight: "bold", opacity: 0.75 }}
              noWrap
            >
              {"Beattheq"}
            </Typography>
          }
        </Toolbar>
      </AppBar>

      <main>
        {/* Hero unit */}
        <div
          className={classes.heroContent}
          style={{
            backgroundImage: `url(${
              ui.businessDetails && ui.businessDetails.banner
                ? ui.businessDetails.banner
                : "https://images.unsplash.com/photo-1432847712612-926caafaa802?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80"
            })`,
          }}
        >
          <Container
            maxWidth="sm"
            style={{
              height: 200,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              style={{ color: "white" }}
              gutterBottom
            >
              {ui.businessDetails && ui.businessDetails.title}
            </Typography>
            <Typography
              style={{ color: "white" }}
              variant="h4"
              align="center"
              color="textSecondary"
              paragraph
            >
              Cancel Appointment
            </Typography>
            {/* <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary">
                    Main call to action
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Secondary action
                  </Button>
                </Grid>
              </Grid>
            </div> */}
          </Container>
        </div>

       { isDeleted?
       <Container className={classes.cardGrid} maxWidth="sm">
    
        <CheckCircleIcon style={{fontSize:80,color:"green"}}/>

        <Typography variant="h5" align="center" style={{marginTop:32}}>
          Appointment succesfully canceled
        </Typography>


       </Container>
       
       
       
       :<Container className={classes.cardGrid} maxWidth="sm">
          <Typography variant="h6" align="center" color="textSecondary">
            Details of booking
          </Typography>
          <br/>
          <Grid container spacing={2} align="center">
           <Grid item xs={12}>
           <Typography variant="body" style={{textTransform: "capitalize"}}>  Name : {bookngDetails.firstName+" "+bookngDetails.lastName}</Typography>
           </Grid>
           <Grid item xs={12}>
           <Typography variant="body" style={{textTransform: "capitalize"}}>  Service : {bookngDetails.service.title}</Typography>
           </Grid>
           <Grid item xs={12}>
           <Typography variant="body" style={{textTransform: "capitalize"}}>  <small style={{fontWeight: "bold" }}> {moment(bookngDetails.start).format("LLL")+" -  "+moment(bookngDetails.end).format("LLL")}</small></Typography>
           </Grid>
  <Grid item xs={12} style={{marginTop:32}}>
               <TextField variant="outlined" 
               error={err}
               helperText={err?err:"Please enter email used to book appointment"}
               onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
           </Grid>

           <Grid item xs={12}>
               <Button variant="contained" 
               onClick={()=>cancelMyBooking(props.match.params.id,email)}
               color="primary">Cancel Appointment</Button>
           </Grid>
           
          </Grid>
        </Container>}
      </main>
      {/* Footer */}
      <Footer />
      {/* End footer */}
    </React.Fragment>
  );
}
