import React ,{useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import Home from "./home"
import Bookings from "./bookings"
import AppBar from '@material-ui/core/AppBar';
import NotFound from "./NotFound"
import About from "./aboutus"
import Policy from "./policy"
import Services from "./services"
import CancelAppointment from "./Cancel"
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import {getBusinessDetails,getServices} from "./store/actions/commonActions"
import { BrowserRouter, Router, Route, Switch, Redirect } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album(props) {
  const classes = useStyles();
  const {  ui ,businessDetails} = useSelector(state => state)

 


  return (
    <React.Fragment>
      <CssBaseline />
      {/* <AppBar variant="fixed" position="relative" style={{background:"#fff",boxShadow:"none"}}>
        <Toolbar>
       
          <Typography variant="h6" color="inherit" style={{color:"#000",fontWeight:"bold"}} noWrap>
          {"Beattheq"}
          </Typography>
        </Toolbar>
      </AppBar> */}
      <BrowserRouter>
      <ToastContainer />
        <Backdrop style={{ zIndex: 2500, color: "#fff" }} open={ui.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
   <Switch>

   <Route
   exact
 path="/:url"
component={Home}/ >
         
         <Route
   exact
 path="/:url/about"
component={About}/ >

<Route
   exact
 path="/:url/services"
component={Services}/ >


<Route
   exact
 path="/:url/policy"
component={Policy}/ >

          <Route
          exact
            path="/:url/bookings/:id"
            component={Bookings}/>
      
              <Route
              exact
              path="/cancel/:id"
              component={CancelAppointment}
              />
          
      <Route
         exact
            path="/page/404"
            component={NotFound}/>

          <Redirect from="*" to="/page/404" />
   </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
}