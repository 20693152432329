import { createStore,combineReducers } from 'redux'


import { uireducer as ui } from './reducers/uireducer'

const reducer=combineReducers({
 
    ui,
  
});
const store=createStore(reducer);
export default store;