export const apis= {
baseUrl: "https://m3vkgiup65.execute-api.eu-west-1.amazonaws.com/dev",
//baseUrl: "http://localhost:3001",
   getBusinessDetails:"/api/business/detailsPublicUrl/",
   getServices:"/api/service/getPublic/",
   getServiceDetails:"/api/service/publicDetails/",
   getAvailableBooking:"/api/booking/availablePublic/",
   sendVerification:"/api/mail/verifyMail",
   createBooking:"/api/booking/createPublic",
   bookingDetails:"/api/booking/getDetails",
   cancelBooking:"/api/booking/cancelPublic"
}