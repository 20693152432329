import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { sendVerifyMail, addBooking } from "../store/actions/commonActions";
import Grid from "@material-ui/core/Grid";
import FaceIcon from "@material-ui/icons/Face";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { toast } from "react-toastify";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Typography } from "@material-ui/core";
import moment from "moment";
import GoogleLogin from "react-google-login";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
var validator = require("validator");
export default function AlertDialog(props) {
  const [booking, setBooking] = React.useState({
    verification: "",
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const [error, setError] = React.useState({
    phone: "",
    email: "",
    firstName: "",
    lastName: "",
    verification: "",
  });

  const [type, setType] = React.useState(null);
  const [bookingData, setBookingData] = React.useState({});
  const [confirmation, setConfirmation] = React.useState(false);
  useEffect(() => {
    if (props.state && props.state.modal) {
    } else {
      setBooking({
        verification: "",
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
      });
      setType(null);
    }
  }, [props.state]);

  useEffect(() => {
    if (props.state && props.state.type) {
      setType(props.state.type);
    } else {
      setType(null);
    }
  }, [props.state]);

  useEffect(() => {
    if (props.state && props.state.verify) {
      setVerifyScreen(props.state.verify);
    } else {
      setVerifyScreen(false);
    }
  }, [props.state]);

  let history = useHistory();

  const handleClose = () => {
    setBooking({
      verification: "",
      phone: "",
      email: "",
      firstName: "",
      lastName: "",
    });

    setError({
      phone: "",
      email: "",
      firstName: "",
      lastName: "",
      verification: "",
    });

    props.handleClose();
  };

  const setErrorState = (data) => {
    setError({ ...error, ...data });
  };

  const [loading, setLoading] = React.useState(false);

  const [verifyScreen, setVerifyScreen] = React.useState(false);

  const setState = (data) => {
    setBooking({
      ...booking,
      ...data,
    });
  };

  const sendVerification = () => {
    var isvalid = true;
    let ermsg = {
      firstName: "",
      email: "",
      phone: "",
    };
    if (!(booking.email && validator.isEmail(booking.email))) {
      console.log(error);
      ermsg = { ...ermsg, email: "Please enter valid email" };
      isvalid = false;
    }

    if (
      !(
        booking.firstName &&
        booking.firstName.length > 2 &&
        validator.isAlpha(booking.firstName)
      )
    ) {
      ermsg = { ...ermsg, firstName: "Please enter valid name" };
      isvalid = false;
    }
    if (booking.lastName && !validator.isAlpha(booking.lastName)) {
      ermsg = { ...ermsg, lastName: "Please enter valid last name" };
      isvalid = false;
    }

    if (
      !(
        booking.phone &&
        booking.phone.length > 5 &&
        validator.isMobilePhone(booking.phone)
      )
    ) {
      ermsg = { ...ermsg, phone: "Please enter valid phone number" };
      isvalid = false;
    }

    setErrorState(ermsg);

    if (isvalid && type == "email")
      sendVerifyMail(booking.email, booking.phone, props.businessName)
        .then((verification) => {
          toast("Verification code sent", {
            type: "success",
            autoClose: 5000,
          });
          history.push(props.url, { type: type, modal: true, verify: true });
        })
        .catch((err) => {
          toast("Unexpected error please try again", {
            type: "error",
            autoClose: 5000,
          });
        });
    else if (isvalid && type == "gmail") addBookingSlot();
  };



  

const checkError=(name)=>{
  var isvalid = true;
  let ermsg = {
    firstName: "",
    email: "",
    phone: "",
  };
  if (!(booking.email && validator.isEmail(booking.email))) {
    console.log(error);
    ermsg = { ...ermsg, email: "Please enter valid email" };
    isvalid = false;
  }

  if (
    !(
      booking.firstName &&
      booking.firstName.length > 2 &&
      validator.isAlpha(booking.firstName)
    )
  ) {
    ermsg = { ...ermsg, firstName: "Please enter valid name" };
    isvalid = false;
  }
  if (booking.lastName && !validator.isAlpha(booking.lastName)) {
    ermsg = { ...ermsg, lastName: "Please enter valid last name" };
    isvalid = false;
  }

  if (
    !(
      booking.phone &&
      booking.phone.length > 5 &&
      validator.isMobilePhone(booking.phone)
    )
  ) {
    ermsg = { ...ermsg, phone: "Please enter valid phone number" };
    isvalid = false;
  }

  setErrorState({[name]: ermsg[name]});
}


  const addBookingSlot = () => {
    let err = { verification: "" };
    let isValid = true;
    if (!(booking.verification && booking.verification.length == 6)) {
      err = { verification: "Please enter valid verification code" };
      isValid = false;
    }

    setErrorState(err);
    console.log(props.reminder);
    if (isValid || type == "gmail") {
      addBooking({
        token: booking.verification,
        business: props.businessId,
        businessName: props.businessName,
        serviceName: props.serviceName,
        businessAddress1: props.businessAddress1,
        businessAddress2: props.businessAddress2,
        service: props.serviceId,
        isVerified: type == "gmail" ? true : false,
        reminders: [
          {
            date: new Date(
              new Date(props.data.start).getTime() -
                props.reminder * 60 * 60 * 1000
            ),

            sent: false,
          },
        ],
        phone: booking.phone,
        email: booking.email,
        note: booking.note,
        firstName: booking.firstName,
        provider: props.data.provider._id,
        lastName: booking.lastName,
        start: props.data.start,
        end: props.data.end,
      })
        .then((data) => {
          setVerifyScreen(false);
          setType(null);
          setBookingData(data);
          // handleClose();
          props.handleAddSuccesss();
          setConfirmation(true);
          toast("Service booked succesfully", {
            type: "success",
            autoClose: 5000,
          });

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status == "409") {
            setErrorState({ verification: "Verification code is expired, try to resend validation code and verify it" });
          } else {
            toast("Unexpected error please try again", {
              type: "error",
              autoClose: 5000,
            });
            setVerifyScreen(false);
            setType(null);
            handleClose();
          }
          // props.handleClose();
          // setTimeout(() => {
          //   setLoading(false);
          // }, 1000);
        });
    }
  };

  const responseGoogle = (response) => {
    console.log(response.profileObj);
    setBooking({
      phone: "",
      email: response.profileObj.email,
      firstName: response.profileObj.givenName,
      lastName: response.profileObj.familyName,
    });
    history.push(props.url, { modal: true, type: "gmail" });
  };

  return (
    <div>
      <Dialog
        open={props.open}
        //onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
              onClick={() => history.goBack()}
            >
              <ArrowBackIosIcon />
            </IconButton>{" "}
            &nbsp; <b>Book a slot</b>
          </div>
        </DialogTitle>
        {type && !confirmation && (
          <DialogContent
            style={{
              paddingBotton: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DialogContentText id="alert-dialog-description">
              {!loading ? (
                <Grid container spacing={2} style={{ maxWidth: 600 }}>
                  <Grid item xs={12}>
                    <center>
                      <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        {props.serviceName}
                      </Typography>
                    </center>
                    <br />
                    <center>
                      {" "}
                      <Typography gutterBottom style={{ fontWeight: "bold" }}>
                        {moment(new Date(props.data.start)).format("LL")}
                      </Typography>
                      <Chip
                        key={1}
                        icon={<FaceIcon />}
                        color={"primary"}
                        label={
                          moment(new Date(props.data.start)).format("LT") +
                          " - " +
                          moment(new Date(props.data.end)).format("LT")
                        }
                      />
                    </center>
                    <br />
                  </Grid>

                  {verifyScreen ? (
                    <div
                      style={{
                        padding: 16,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography gutterBottom>
                        A verification code has been sent to your email ,Please
                        verify your email by typing code in field below
                      </Typography>
                      <Grid
                       xs={12}
                       lg={6}
                        item
                        style={{marginTop: 16 }}
                      >
                        <TextField
                          error={error.verification}
                          helperText={
                            error.verification
                              ? error.verification
                              : "Please enter 6 digit verification code"
                          }
                          fullWidth
                          variant="outlined"
                          placeholder="Verification code"
                          onChange={(e) =>
                            setState({ verification: e.target.value })
                          }
                        />
                        
                      </Grid>
                      <Grid xs={12} lg={4}>
                      <Button variant="contained" style={{marginTop:16}} onClick={()=>sendVerification()}>Resend</Button>
                      </Grid>
                    </div>
                  ) : (
                    <>
                      <Grid xs={12} md={6} item style={{ display: "flex" }}>
                        <TextField
                          fullWidth
                          error={error.firstName}
                          helperText={error.firstName}
                          value={booking.firstName}
                          onBlur={()=>checkError("firstName")}
                          variant="outlined"
                          placeholder="First name"
                          onChange={(e) =>
                            setState({ firstName: e.target.value })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FaceIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid xs={12} md={6} item style={{ display: "flex" }}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Last name"
                          error={error.lastName}
                          value={booking.lastName}
                          onChange={(e) =>
                            setState({ lastName: e.target.value })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FaceIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid xs={12} style={{ display: "flex" }} item>
                        <TextField
                          variant="outlined"
                          error={error.phone}
                          helperText={error.phone}
                          onBlur={()=>checkError("phone")}
                          value={booking.phone}
                          fullWidth
                          placeholder="Mobile"
                          onChange={(e) => setState({ phone: e.target.value })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid xs={12} style={{ display: "flex" }} item>
                        <TextField
                          variant="outlined"
                          fullWidth
                          error={error.email}
                          disabled={type == "gmail"}
                          helperText={error.email}
                          onBlur={()=>checkError("email")}
                          value={booking.email}
                          placeholder="Email"
                          onChange={(e) => setState({ email: e.target.value })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid xs={12} style={{ display: "flex" }} item>
                        <TextField
                          variant="outlined"
                          fullWidth
                          multiline={true}
                          rows={3}
                          placeholder="Note or any instruction"
                          onChange={(e) => setState({ note: e.target.value })}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <center>
                  <CircularProgress />
                </center>
              )}
              <br />
              {!loading && !confirmation ? (
                <DialogActions style={{ marginBottom: 8 }}>
                  <Button
                    onClick={() => {
                      history.goBack();
                    }}
                    variant="contained"
                    color="secondary"
                  >
                    back
                  </Button>
                  {verifyScreen ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => addBookingSlot()}
                      autoFocus
                    >
                      Verify Now
                    </Button>
                  ) : (
                    type && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => sendVerification()}
                        autoFocus
                      >
                        Book Now
                      </Button>
                    )
                  )}
                </DialogActions>
              ) : (
                <DialogActions style={{ marginBottom: 8 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      props.handleClose();
                      setConfirmation(false);
                    }}
                    autoFocus
                  >
                    Done
                  </Button>
                </DialogActions>
              )}
            </DialogContentText>
          </DialogContent>
        )}

        {!type && !confirmation && (
          <DialogContent
            style={{
              marginBottom: 24,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} style={{ maxWidth: 600 }}>
              <Grid item xs={12}>
                <GoogleLogin
                  clientId="629516739525-ktduudc2kf963b7a0ltnt8uhkqfk8uni.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      fullWidth
                      variant={"contained"}
                      color="secondary"
                      disabled={renderProps.disabled}
                    >
                      Continue with google
                    </Button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                />
              </Grid>
              <Grid item xs={12}>
                <center>or</center>
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() =>
                    history.push(props.url, { type: "email", modal: true })
                  }
                  variant="contained"
                  fullWidth
                  color="primary"
                >
                  Continue with eamil
                </Button>
              </Grid>
            </Grid>
            <br />
            {!loading && !confirmation ? (
              <DialogActions style={{ marginBottom: 8 }}>
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  back
                </Button>
                {verifyScreen ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => addBookingSlot()}
                    autoFocus
                  >
                    Verify Now
                  </Button>
                ) : (
                  type && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => sendVerification()}
                      autoFocus
                    >
                      Book Now
                    </Button>
                  )
                )}
              </DialogActions>
            ) : (
              <DialogActions style={{ marginBottom: 8 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.handleClose();
                    setConfirmation(false);
                  }}
                  autoFocus
                >
                  Done
                </Button>
              </DialogActions>
            )}
          </DialogContent>
        )}

        {confirmation && (
          <DialogContent
            style={{
              paddingBotton: 32,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              style={{ fontSize: 100, color: "green", marginBottom: 24 }}
            />

            <Typography variant="h4" gutterBottom>
              Booking Confirmed
            </Typography>

            <Typography
              gutterBottom
              color="textSecondary"
              variant="h5"
              style={{ fontWeight: "bold" }}
            >
              {props.serviceName}
            </Typography>

            <Typography
              variant="h6"
              color="primary"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              Reference Id : {bookingData.details.referenceId}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              style={{ fontWeight: "bold" }}
              gutterBottom
            >
              Date : {moment(new Date(bookingData.details.start)).format("LLL")}
            </Typography>

            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <br />
            {!loading && !confirmation ? (
              <DialogActions style={{ marginBottom: 8 }}>
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  back
                </Button>
                {verifyScreen ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => addBookingSlot()}
                    autoFocus
                  >
                    Verify Now
                  </Button>
                ) : (
                  type && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => sendVerification()}
                      autoFocus
                    >
                      Book Now
                    </Button>
                  )
                )}
              </DialogActions>
            ) : (
              <DialogActions style={{ marginBottom: 8 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.handleClose();
                    setConfirmation(false);
                    history.push(`/${props.shortUrl}`);
                  }}
                  autoFocus
                >
                  Done
                </Button>
              </DialogActions>
            )}
          </DialogContent>
        )}

        {/* 

        {!loading &&!confirmation? (
          <DialogActions style={{ marginBottom: 8, marginRight: 16 }}>
            <Button
              onClick={() => {
                history.goBack()
              
              }}
              variant="outlined"
              color="secondary"
            >
              back
            </Button>
            {verifyScreen ? (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => addBookingSlot()}
                autoFocus
              >
                Verify Now
              </Button>
            ) : (
              type && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => sendVerification()}
                  autoFocus
                >
                  Book Now
                </Button>
              )
            )}
          </DialogActions>
        ):   <DialogActions style={{ marginBottom: 8, marginRight: 16 }}>
      
    
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {props.handleClose();setConfirmation(false)}}
            autoFocus
          >
            Done
          </Button>
      
      </DialogActions>} */}
      </Dialog>
    </div>
  );
}
