import { LOADING, STOP_LOADING } from "../types"

const INITIAL={
    loading:false,
    businessDetails:null
}
export const uireducer=(state=INITIAL,action)=>{
    switch (action.type) {
        case LOADING:
            return {...state,
            loading:true
            }
            break;
        case STOP_LOADING:
            return {...state,
                loading:false
                }

                case "SET_BUSSINESS_DETAILS":
                return {
                    ...state,
                    businessDetails:action.payload
                }
            break;
        default:
            return state;
            break;
    }
}