import React ,{useEffect,useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import {useSelector} from "react-redux"
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Footer from "./common/footer"

import {getBusinessDetails,getServices} from "./store/actions/commonActions"
import Link from '@material-ui/core/Link';
import EventIcon from '@material-ui/icons/Event';
import ButtonBase from "@material-ui/core/ButtonBase";
import EmailIcon from '@material-ui/icons/Email';
import DescriptionIcon from '@material-ui/icons/Description';
import CallIcon from '@material-ui/icons/Call';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SubjectIcon from '@material-ui/icons/Subject';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { BrowserRouter, Router, Route, Switch, Redirect ,useHistory} from 'react-router-dom';
function Copyright() {




   



  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundImage:`url(https://images.unsplash.com/photo-1432847712612-926caafaa802?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80)`,
    color: "white",

    backgroundSize:"cover",
 
  
  },
  banner:{
    background: "rgba(0,0,0,0.4)",
    backdropFilter: "saturate(180%) blur(1px)"
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  buttonBase:{
    padding:16,
    paddingTop:24,
    alignItems: 'center',
    justifyContent:"space-between",
    paddingBottom:24,
    fontSize:16,
    fontWeight:"bold",
    borderRadius:4,
    width:"100%",
        color:"white",
    backgroundColor: theme.palette.primary.main
  }
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
const [businessData,setBussinessData]=useState(null)
const [services,setServiceData]=useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {  ui } = useSelector(state => state)

  useEffect(async()=>{
   const bus= await getBusinessDetails(props.match.params.url)
   console.log(bus)
const services=await getServices(bus.details._id)

setServiceData(services.data)

  },[])

  useEffect(async()=>{
    window.scrollTo(0,0)
  
   },[])

  let history=useHistory()
  console.log(history)
  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar variant="fixed" position="relative" style={{background:"#fff",boxShadow:"none"}}>
        <Toolbar>
       { <Typography variant="h6" color="inherit" style={{color:"#000",fontWeight:"bold",opacity:0.75}} noWrap>
          {"Beattheq"}
          </Typography>}
         
        </Toolbar>
      </AppBar>
      
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent} style={{backgroundImage:`url(${ui.businessDetails&&ui.businessDetails.banner?ui.businessDetails.banner:"https://images.unsplash.com/photo-1432847712612-926caafaa802?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1950&q=80"})`}}>
          <div  style={{height:300,width:"100vw",display:"flex",justifyContent:"center",flexDirection:"column"}} className={classes.banner}>
            <Typography component="h1" variant="h4" align="center" color="textPrimary" style={{color:"white"}} gutterBottom>
            {ui.businessDetails&&ui.businessDetails.title}
            </Typography>
            <Typography style={{color:"white"}} variant="body1" align="center" color="textSecondary" paragraph dangerouslySetInnerHTML={{__html:ui.businessDetails&&ui.businessDetails.description}}>
            
            </Typography>
            {/* <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary">
                    Main call to action
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary">
                    Secondary action
                  </Button>
                </Grid>
              </Grid>
            </div> */}
          </div>
        </div>
        {/* <Paper square>
        <Tabs
        centered
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label="services" />
        <Tab label="About us"  />
        <Tab label="Policy" />
      </Tabs>
</Paper> */}

        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}


          {/* <Grid container spacing={4} justify="center" align="center">



            {value==0&&services.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://source.unsplash.com/random"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" component="h2" >
                      {card.title}
                    </Typography>
                    <Typography gutterBottom color="textSecondary" dangerouslySetInnerHTML={{__html:card.description}}>
            
                    </Typography>
<br/>
                    <Typography style={{fontSize:18,fontWeight:"bold"}}
                    >
            Price: € {card.price}
            </Typography>
                  </CardContent>
                  <CardActions style={{marginBottom:8,marginLeft:8}}>
                    <Button variant="outlined" size="small" color="primary" onClick={() =>history.push("/"+props.match.params.url+"/bookings/"+card._id)}>
                      Book Now
                    </Button>
                   
                  </CardActions>
                </Card>
              </Grid>
            ))}


            {value==1&&
            <Typography color="textSecondary" style={{padding:24}} dangerouslySetInnerHTML={{__html:ui.businessDetails&&ui.businessDetails.aboutUs}}>

  

            </Typography>
            
            
            }


{value==2&&
               <Typography color="textSecondary" style={{padding:24}} dangerouslySetInnerHTML={{__html:ui.businessDetails&&ui.businessDetails.policy}}>

  

               </Typography>
            
            
            }

          </Grid> */}
<Grid container spacing={4}>
<Grid item lg={8} xs={12}>

  <Grid container spacing={2}>


    <Grid item xs={12}>
    <ButtonBase 
    onClick={() =>props.history.push(`/${props.match.params.url}/services`)}
    
    color="primary" className={classes.buttonBase} fullWidth>
  <div style={{display: "flex",flexDirection:"row",justifyContent:"center",alignItems:"center" }}>
  <EventIcon style={{marginRight:24,fontSize:24}} /> Schedule an appointment for a service
  </div>
 <ArrowForwardIosIcon style={{fontSize:24}}/>
</ButtonBase>
    </Grid>

    <Grid item xs={12}>
    <ButtonBase color="primary" className={classes.buttonBase} onClick={() =>props.history.push(`/${props.match.params.url}/policy`)} fullWidth>
  <div style={{display: "flex",flexDirection:"row",justifyContent:"center",alignItems:"center" }}>
  <SubjectIcon style={{marginRight:24,fontSize:24}} /> Notice board
  </div>
 <ArrowForwardIosIcon style={{fontSize:24}}/>
</ButtonBase>
    </Grid>

    <Grid item xs={12}>
    <ButtonBase color="primary" className={classes.buttonBase} onClick={() =>props.history.push(`/${props.match.params.url}/about`)} fullWidth>
  <div style={{display: "flex",flexDirection:"row",justifyContent:"center",alignItems:"center" }}>
  <DescriptionIcon style={{marginRight:24,fontSize:24}} /> About us
  </div>
 <ArrowForwardIosIcon style={{fontSize:24}}/>
</ButtonBase>
    </Grid>

    <Grid item xs={12}>
    <ButtonBase color="primary" className={classes.buttonBase} fullWidth href="tel:+918890200198">
  <div style={{display: "flex",flexDirection:"row",justifyContent:"center",alignItems:"center" }}>
  <CallIcon style={{marginRight:24,fontSize:24}} /> Call us at +918890200198
  </div>
 <ArrowForwardIosIcon style={{fontSize:24}}/>
</ButtonBase>
    </Grid>



  </Grid>

 


</Grid>
<Grid item lg={4} xs={12} >
<Paper elevation={0} style={{borderRadius:2,padding:8}} > <center>
  <span style={{fontSize:16,fontWeight:"bold"}}>{ui.businessDetails&&ui.businessDetails.title}</span> <br/>

  <small dangerouslySetInnerHTML={{__html:ui.businessDetails&&ui.businessDetails.description}}>
 
  </small>
  
  
  
  </center>
<br/>
  <Grid container spacing={2}>
    <Grid item xs={12} > <b style={{alignItems:"center",display:"flex"}}><CallIcon style={{marginRight:12}}/>{ui.businessDetails&&ui.businessDetails.mobile}</b></Grid>
    <Grid item xs={12}><b style={{alignItems:"center",display:"flex"}}><EmailIcon style={{marginRight:12}}/>{ui.businessDetails&&ui.businessDetails.email}</b></Grid>
    <Grid item xs={12}><b style={{alignItems:"center",display:"flex"}}><LocationOnIcon style={{marginRight:12,textTransform:"capitalize"}}/>{ui.businessDetails&&(ui.businessDetails.address.description+" , "+ui.businessDetails.address.city)}</b></Grid>
  </Grid>

</Paper>

  </Grid>
</Grid>

        </Container>
      </main>
      {/* Footer */}
 <Footer/>
      {/* End footer */}

    </React.Fragment>
  );
}