import React, { useEffect, useState } from "react";
import "date-fns";
import Chip from "@material-ui/core/Chip";
import AddBooking from "./bookings/AddBooking";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useSelector } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Footer from "./common/footer"
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  DatePicker ,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  getBusinessDetails,
  getServiceDetails,
  getServiceBookings,
} from "./store/actions/commonActions";
import Link from "@material-ui/core/Link";

import _ from "lodash";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  date: {
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
   
    padding: theme.spacing(8, 0, 6),
  
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
 
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  chip: {
    margin: 4,
    width:140,
    fontSize:10,
    borderRadius:"8px"
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album(props) {
  const classes = useStyles();
  const [modal, openModal] = useState(false);
  const [modalAdd, openModalAdd] = useState(false);
  const [BookingdataModal, setBookingDataMOdal] = useState({});

  const [bookingData, setBookingData] = useState([]);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const { ui, businessDetails } = useSelector((state) => state);

  useEffect(async () => {
    if(!ui.businessDetails)
    {const bus= await getBusinessDetails(props.match.params.url)}
    const Details = await getServiceDetails(props.match.params.id);
    setServiceDetails(Details);

    if(!BookingdataModal._id){
      openModalAdd(false)
    }


  }, []);

  useEffect(async () => {
    if(ui.businessDetails)
    getServiceBookings(
      ui.businessDetails && ui.businessDetails._id,
      props.match.params.id,
      selectedDate
    ).then((Bdata) => {
      setBookingData(Bdata);
    });
  }, [selectedDate, ui.businessDetails]);

  const handleAddSuccesss = () => {

    getServiceBookings(
      ui.businessDetails && ui.businessDetails._id,
      props.match.params.id,
      selectedDate
    ).then((Bdata) => {
      setBookingData(Bdata);
    });
  };



  useEffect( () => {
    if(props.location.state&&props.location.state.modal) {
      openModalAdd(true)
    }
    else
    {
      openModalAdd(false)
    }
  },[
    props.location.state
  ])



  
  return (
    <React.Fragment>
      <CssBaseline />

      <AddBooking
        open={modalAdd}
        handleClose={() => openModalAdd(false)}
        state={props.location.state}
        url={props.location.url}
        data={BookingdataModal}
        shortUrl={ui.businessDetails&&ui.businessDetails.shortUrl}
        businessId={ui.businessDetails && ui.businessDetails._id}
        businessName={ui.businessDetails && ui.businessDetails.title}
        businessAddress1={ui.businessDetails && ui.businessDetails.address.description}
        businessAddress2={ui.businessDetails && ui.businessDetails.address.city}
        serviceName={serviceDetails&&serviceDetails.details.title}
        serviceId={props.match.params.id}
        handleAddSuccesss={handleAddSuccesss}
        reminder={serviceDetails&&serviceDetails.details.reminder}
      />
      {/* <AppBar position="relative">
        <Toolbar>
       
          <Typography variant="h6" color="inherit" noWrap>
          {businessData&&businessData.details.title}
          </Typography>
        </Toolbar>
      </AppBar> */}
      
      <AppBar variant="fixed" position="relative" style={{background:"#fff",boxShadow:"none"}}>
        <Toolbar>
{ props.history.length&&<IconButton style={{justifyContent:"center",alignItems:"center",display:"flex"}} onClick={()=>props.history.goBack()}><ArrowBackIosIcon/></IconButton>}

       {<Typography variant="h6" color="inherit" style={{color:"#000",fontWeight:"bold",opacity:0.75}} noWrap>
          {"Beattheq"}
          </Typography>}
         
        </Toolbar>
      </AppBar>
      
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container >
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {serviceDetails && serviceDetails.details.title}
            </Typography> 
            <br/>
            <br/>
            
<Grid container >

            <Grid item xs={12} sm={12} lg={4}  >  
            <center>
             <div className={classes.date}>
         
              <MuiPickersUtilsProvider utils={DateFnsUtils}>

                
      <DatePicker
        autoOk
        disablePast
        maxDate={new Date(new Date().getTime()+24*60*60*1000*(serviceDetails && serviceDetails.details.advanceBooking?serviceDetails.details.advanceBooking:10))}
        variant="static"
        openTo="date"
        value={selectedDate}
        onChange={handleDateChange}
      />
                {/* <KeyboardDatePicker
                disablePast
                  inputVariant="outlined"
                  margin="normal"
                  id="date-picker-dialog"
                  label="Select date of booking"
                  format="MM/dd/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                /> */}
              </MuiPickersUtilsProvider>
           
            </div>
            </center></Grid>

            <Grid item xs={12} lg={8}>
            <Container className={classes.cardGrid} >
          {/* End hero unit */}
         { Object.values(_.groupBy(bookingData, (i) => i.provider._id)).length==0&&<center style={{marginTop:80}}><h2>No appointments Available for selected date</h2></center>}
          {Object.values(_.groupBy(bookingData, (i) => i.provider._id)).map(
            (item) => {
              return (
                <>
                  <h2
                    style={{
                      textTransform: "capitalize",
                      marginLeft: 24,
                      fontSize: 18,
                    }}
                  >
                    Service Provider: {item[0].provider.firstName}
                  </h2>
                  <Divider />
                  <br />
                  <div style={{display: "flex",flexDirection: "row",alignItems: "center",flexWrap:"wrap"}}>
                    {item.map((slot, index) => {

                      let valid=new Date(slot.start).getTime()>new Date().getTime()
                      return (
                        <Chip
                          key={index}
                          onClick={() => {
                            !slot.booked&&valid &&props.history.push(props.location.url,{modal:true}) ;
                            setBookingDataMOdal(slot);
                          }}
                          icon={slot.booked && <FaceIcon />}
                          color={slot.status > 0 ? "secondary" : "primary"}
                          style={{backgroundColor:valid?undefined:"grey"}}
                          className={classes.chip}
                          label={
                            moment(slot.start).format("LT") +
                            " - " +
                            moment(slot.end).format("LT")
                          }
                          deleteIcon={<DoneIcon />}
                        />
                      );
                    })}
                  </div>
                </>
              );
            }
          )}
        </Container>
            </Grid>
            </Grid>
         
          </Container>
        </div>

      
      </main>
      {/* Footer */}
    <Footer/>
      {/* End footer */}
    </React.Fragment>
  );
}
