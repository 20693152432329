import React ,{useEffect,useState} from 'react';


import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Link from '@material-ui/core/Link';

function Copyright() {




   



  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Beattheq
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },

}));



export default function Footer() {
    const classes = useStyles();
  return (
    <React.Fragment>
   
      {/* Footer */}
      <footer className={classes.footer}>
    
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          Powered by beatheq.ie
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}

    </React.Fragment>
  );
}