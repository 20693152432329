import axios from 'axios';
import {apis} from '../../utils/api';
import store from '../store';
import { LOADING, STOP_LOADING } from '../types';

export function getBusinessDetails(id){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.get(`${apis.baseUrl}${apis.getBusinessDetails}${id}`)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})

            store.dispatch({ type:"SET_BUSSINESS_DETAILS",payload:response.data.details})
            console.log(response.data.details)
            resolve(response.data)
        })
        .catch(err=>{
            window.location="/"
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}


export function getServices(id){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.get(`${apis.baseUrl}${apis.getServices}${id}`)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}



export function getServiceDetails(id){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.get(`${apis.baseUrl}${apis.getServiceDetails}${id}`)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}


export function getServiceBookings(bid,sid,data){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.get(`${apis.baseUrl}${apis.getAvailableBooking}${bid}/${sid}/${data}`)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}


export function sendVerifyMail(email,phone,businessName){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.post(`${apis.baseUrl}${apis.sendVerification}`,{
            email: email,
            mobile: phone,
            businessName:businessName
        })
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}


export function addBooking(data){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.post(`${apis.baseUrl}${apis.createBooking}`,{
            ...data
        })
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}


export function getBookingDetails(id){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.get(`${apis.baseUrl}${apis.bookingDetails}/${id}`)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}
export function cancelBooking(id,email){
    return new Promise((resolve, reject) => {
        store.dispatch({ type: LOADING })
        axios.put(`${apis.baseUrl}${apis.cancelBooking}/${id}/${email}`)
        .then(response=>{
            store.dispatch({ type:STOP_LOADING})
            resolve(response.data)
        })
        .catch(err=>{
            store.dispatch({ type:STOP_LOADING})
            reject(err)
        })
    })
}